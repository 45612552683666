import React from 'react'
import Layout from '../containers/Layout'
import SEO from '../containers/seo'
import AddOrg from '../components/organization/AddOrg'
import NomOrg from '../components/organization/NomOrg'

const addOrganization = () => {
  return (
    <Layout>
      <div className='organization-grid-wrapper'>
        <AddOrg />
        <div className='grid-item org-photo-one'></div>
        <NomOrg />
        <div className='grid-item org-photo-two'></div>
      </div>
    </Layout>
  )
}

export default addOrganization

export const Head = () => (
  <>
    <title>Add Organization | Food Rescue Locator</title>
  </>
)
