import React from 'react'
import { Link } from "gatsby";
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../common/drawers/drawerActions';



const AddOrg = () => {
  const dispatch = useDispatch()

  return (
    <div className="grid-item add-organization">
      <h1 className="h2">Add Your Organization</h1>
      <p>Manage a food rescue or food recovery organization? Add your group to the Food Rescue Locator using our submission form.</p>
      <button className="btn btn-dark" onClick={() => {
        dispatch(openDrawer('OrgSubmissionFormDrawer'))
        }}>Submission Form</button>
    </div>
  )
}

export default AddOrg
