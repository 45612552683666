import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import LoadingComponent from "../../common/ui/LoadingComponent";
import TextInput from "../Forms/TextInput";
import EmailInput from "../Forms/EmailInput";

const NomOrg = () => {
  const [loading, setLoading] = useState(false);
  const [postError, setPostError] = useState(false);

  const initialValues = {
    nomination_url: "",
    nomination_email: "",
  };

  const validationSchema = Yup.object().shape({
    nomination_url: Yup.string().required("Website Required"),
    nomination_email: Yup.string().required("Email Required"),
  });

  const handleSubmit = async (values) => {
    const postData = {
      title: `New submission... ${new Date().toLocaleDateString("en-US")}`,
      fields: values,
    };

    try {
      setLoading(true);
      await fetch(process.env.GATSBY_WP_SUBMIT_LEAD_API, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(postData),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setPostError(true);
    }
  };
  

  return (
    <div className="grid-item nominate-organization">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
          isSubmitting,
          submitCount,
        }) => (
          <Form id="lead-form">
            {loading ? (
              <LoadingComponent />
            ) : (
              <>
                {submitCount >= 1 ? (
                  <div>
                    {postError ? (
                      <>
                        <h3 className="text-danger">Error:</h3>
                        <p>
                          There was an error during form submission please try
                          again.
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={() => resetForm()}
                        >
                          Nominate another organization
                        </button>
                      </>
                    ) : (
                      <>
                        <h2>We got it!</h2>
                        <p>Thank you for helping us grow the Food Rescue Locator.</p>
                        <button
                          className="btn btn-primary"
                          onClick={() => resetForm()}
                        >
                          Nominate another organization
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <h2>Nominate an organization</h2>
                    <p>
                      Know of a food rescue group near you that isn’t included
                      in the directory? Nominate them below and we’ll reach out
                      to add them to the list.
                    </p>
                    <div id="nomFields">
                      <Field
                        autoComplete="off"
                        name="nomination_url"
                        type="text"
                        component={TextInput}
                        onChange={(value) =>
                          setFieldValue("nomination_url", value)
                        }
                        placeholder="Website"
                      />
                      <Field
                        autoComplete="off"
                        name="nomination_email"
                        type="text"
                        component={EmailInput}
                        onChange={(value) =>
                          setFieldValue("nomination_email", value)
                        }
                        placeholder="Email"
                      />
                    </div>
                    <button
                      type="submit"
                      form="lead-form"
                      className={`btn btn-dark ${
                        dirty || isValid ? "" : "btn-disabled"
                      } `}
                      disabled={dirty || isValid ? false : true}
                    >
                      Submit
                    </button>
                  </>
                )}
              </>
            )}
            {/* <FormikDebug /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NomOrg;
